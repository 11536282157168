const fr = {
  fr: {
    translation: {
      collections: {
        collection: {
          create: 'Créer un dossier',
          create_dialog: 'Création d\'un dossier',
          created: 'Dossier créé',
          delete: 'Supprimer le dossier',
          delete_dialog_one: 'Supprimer ce dossier',
          delete_dialog_other: 'Supprimer ces {{count}} dossiers',
          delete_one: 'Êtes-vous sûr de vouloir supprimer le dossier suivant ?',
          delete_other: 'Êtes-vous sûr de vouloir supprimer les dossiers suivants ?',
          deleted_one: '{{count}} dossier supprimé',
          deleted_other: '{{count}} dossiers supprimés',
          edit: 'Modifier le dossier',
          edited: 'Dossier modifié',
          edit_dialog: 'Modification d\'un dossier',
          error_creating: 'Erreur lors de la création d\'un dossier',
          error_deleting_one: 'Erreur lors de la suppression d\'un dossier',
          error_deleting_other: 'Erreur lors de la suppression des dossiers',
          error_editing: 'Erreur lors de la modification d\'un dossier',
          params: 'Paramètre du dossier',
          type: 'Dossier',
          description_placeholder: 'Description du dossier...',
          libelle_placeholder: 'Le nom du dossier...',
        },
        corpus: {
          add: 'Ajouter une sono',
          create: 'Créer une sono',
          create_dialog: 'Création d\'une sono',
          created: 'Sono créée',
          delete: 'Supprimer la sono',
          delete_dialog_one: 'Supprimer cette sono',
          delete_dialog_other: 'Supprimer ces {{count}} sonos',
          delete_one: 'Êtes-vous sûr de vouloir supprimer la sono suivante ?',
          delete_other: 'Êtes-vous sûr de vouloir supprimer les sonos suivantes ?',
          deleted_one: '{{count}} sono supprimée',
          deleted_other: '{{count}} sonos supprimées',
          description_placeholder: 'Cette sono porte sur le sujet de...',
          edit: 'Modifier la sono',
          edited: 'Sono modifiée',
          edit_dialog: 'Modification d\'une sono',
          error_activated: 'Erreur lors de l\'activation d\'une sono',
          error_creating: 'Erreur lors de la création d\'une sono',
          error_deleting_one: 'Erreur lors de la suppression d\'une sono',
          error_deleting_other: 'Erreur lors de la suppression des sonos',
          error_editing: 'Erreur lors de la modification d\'une sono',
          libelle_placeholder: 'Le nom de la sono...',
          params: 'Paramètres de la sono',
          type: 'Sono',
        },
        cannot_add_corpus: 'Vous ne pouvez pas créer de sono, vous n\'avez pas de dossiers à vous',
        cannot_add_not_owner: 'Vous ne pouvez pas créer de sono, vous n\'êtes pas le propriétaire',
        cannot_delete_has_corpus: 'Veuillez supprimer les sonos présentes afin de pouvour supprimer le dossier',
        error_fetching: 'Erreur lors de la récupération d\'un dossier',
        lastest_activated: 'Sonos récemment ouvertes',
        my_collections: 'Mes dossiers',
        no_results: 'Aucun dossier',
        no_search_results: 'Aucun dossier trouvé',
        search: 'Rechercher une sono ou un dossier...',
      },
    },
  },
};

export default fr;
