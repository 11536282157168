import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Box,
  FormControlLabel,
  FormGroup,
  Link,
  Switch,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  AccountBalance,
  Place,
  Label,
  AccountCircle,
  Audiotrack,
  Videocam,
  Grass,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ImgLoader from 'generic/components/ui/ImgLoader';
import ExpandableTagList from 'generic/components/ui/ExpandableTagList';
import ImageNotFound from 'generic/components/ui/ImageNotFound';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { overrideRessource } from 'generic/utils/utils';
import { documentPropType } from 'generic/core/qes/proptypes';
import MediaWithTranscription from 'generic/components/ui/MediaWithTranscription';
import JsxJoinerWithSeparator from 'generic/components/ui/JsxJoinerWithSeparator';

const { default: useDocumentFields } = overrideRessource('core/hooks/useDocumentFields', true);
const { CLICK_ON_DOCS_TITLE_BEHAVIOR } = QES_CONSTANTS;

const Document = ({
  activeBaseId = null,
  activeUserId = null,
  document,
  disableTagsRefine = false,
  displayTitle = true,
  handleGetDocumentComplete = null,
  isCompleteDisplay = false,
  patchDocumentInComplete = null,
  shrinkDocumentDisplay = false,
  isTranslatedTextDisplayed = false,
  handleToggleTranslatedTextDisplay = null,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanMedium = useMediaQuery(theme.breakpoints.down('md'));
  const {
    attachedFileURL,
    date,
    isAudio,
    isVideo,
    linkout,
    textTranslated,
    snippet,
    source,
    thumb,
    title,
    transcription,
    transcriptionLocation,
  } = useDocumentFields(document, isCompleteDisplay);

  const { qesdocument } = document;

  let titleLinkProps;
  if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'newTab') {
    titleLinkProps = {
      component: Link,
      href: linkout,
      rel: 'noopener',
      target: '_blank',
      underline: 'none',
    };
  } else if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'complete') {
    titleLinkProps = {
      component: 'span',
      onClick: handleGetDocumentComplete,
    };
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        width: '100%',
        wordBreak: 'break-word',
        flexGrow: '1',
      }}
    >
      {displayTitle && (
        <Typography
          variant="h6"
          component="span"
          sx={{
            fontSize: '1.2rem',
            margin: '6px 0',
            display: 'block',
          }}
        >
          <Box
            {...titleLinkProps}
            color="text.primary"
            sx={{ cursor: 'pointer' }}
          >
            {title}
          </Box>
        </Typography>
      )}
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Box sx={{ flexGrow: '1' }}>
          {!smallerThanMedium && thumb && (
            <Box sx={{ mr: 1, mb: '2px', float: 'left' }}>
              <ImgLoader
                src={thumb}
                fallbackComponent={(
                  <ImageNotFound
                    height={60}
                    width={100}
                  />
                )}
                height={60}
                width={100}
                loading="lazy"
              />
            </Box>
          )}
          <Box sx={{ lineHeight: '1.3rem' }}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                mb: 0.5,
              }}
            >
              <JsxJoinerWithSeparator items={[
                date && (
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                    className="item"
                  >
                    {transcriptionLocation
                      ? t('document.date_in_location', { when: date, where: transcriptionLocation })
                      : date }
                  </Typography>
                ),
                source && (
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                    className="item"
                  >
                    {source}
                  </Typography>
                ),
                !shrinkDocumentDisplay && CLICK_ON_DOCS_TITLE_BEHAVIOR === 'complete' && linkout && (
                  <Link
                    href={linkout}
                    rel="noopener"
                    target="_blank"
                    underline="none"
                    className="item"
                  >
                    <Typography
                      component="span"
                      variant="body2"
                    >
                      {t('actions.open_in_new_tab')}
                    </Typography>
                  </Link>
                ),
                !isCompleteDisplay && (isAudio || isVideo) && (
                  <Fragment>
                    {isVideo && <Videocam fontSize="small" />}
                    {isAudio && <Audiotrack fontSize="small" />}
                  </Fragment>
                ),
              ]}
              />
            </Box>
          </Box>

          {!isCompleteDisplay && (
            <Box component="span" sx={{ lineHeight: '1.3rem' }}>
              {snippet}
            </Box>
          )}
          {isCompleteDisplay && textTranslated && (
            <FormGroup>
              <FormControlLabel
                checked={isTranslatedTextDisplayed}
                control={<Switch onChange={handleToggleTranslatedTextDisplay} />}
                label={t('results.switch_translated_text')}
                sx={{ pl: '11px' }}
              />
            </FormGroup>
          )}
          {!shrinkDocumentDisplay && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
              <ExpandableTagList
                disableRefine={disableTagsRefine}
                qesdocument={qesdocument}
                tags={[
                  { fieldName: 'Categorie' },
                ]}
                displayAll
              />
              <ExpandableTagList
                disableRefine={disableTagsRefine}
                qesdocument={qesdocument}
                tags={[
                  { fieldName: 'QES_Company', icon: AccountBalance },
                  { fieldName: 'QES_Person', icon: AccountCircle },
                  { fieldName: 'QES_Location', icon: Place },
                  { fieldName: 'QES_ConceptCategorized', icon: Label },
                  { fieldName: 'QES_Stupefiants', icon: Grass },
                  { fieldName: 'QES_StupefiantsOriginal', icon: Grass },
                ]}
              />
            </Box>
          )}
        </Box>
      </Box>
      {isCompleteDisplay && (isAudio || isVideo) && (
        <MediaWithTranscription
          activeBaseId={activeBaseId}
          activeUserId={activeUserId}
          documentId={document.idext}
          displayTranslatedSentences={!_.isEmpty(textTranslated) && isTranslatedTextDisplayed}
          mediaType={isVideo ? 'video' : 'audio'}
          mediaUrl={attachedFileURL}
          patchDocumentInComplete={patchDocumentInComplete}
          transcription={transcription}
          hasDisplayDateTime
        />
      )}
    </Box>
  );
};

Document.propTypes = {
  activeBaseId: PropTypes.number,
  activeUserId: PropTypes.number,
  disableTagsRefine: PropTypes.bool,
  displayTitle: PropTypes.bool,
  document: documentPropType.isRequired,
  handleGetDocumentComplete: PropTypes.func,
  isCompleteDisplay: PropTypes.bool,
  patchDocumentInComplete: PropTypes.func,
  shrinkDocumentDisplay: PropTypes.bool,
  isTranslatedTextDisplayed: PropTypes.bool,
  handleToggleTranslatedTextDisplay: PropTypes.func,
};

export default Document;
