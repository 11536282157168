import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { t } from 'i18next';
import { toZonedTime } from 'date-fns-tz';

import { format } from 'generic/utils/dateUtils';
import {
  generateLinkToResource, getDocumentBaseId, getValueByBaseFieldsConfig, getValueOrFirstValueFromArray,
} from 'generic/utils/qesUtils';
import { useComputedTranscription } from 'generic/core/hooks/useComputedTranscription';
import { useColorSchemeDetector } from 'generic/core/hooks/useColorSchemeDetector';
import { cleanMultipleLines } from 'generic/utils/utils';

export default (document, shouldComputeCompleteFields) => {
  const { qesdocument } = document;

  const docBaseId = getDocumentBaseId(document);
  const documentBase = useSelector((state) => _.find(state.config.bases, { base: docBaseId }));
  const resultsCompleteVisibleInResults = useSelector((state) => state.ux.resultsCompleteVisibleInResults);
  const currentTheme = useColorSchemeDetector();
  const { speakersColors } = currentTheme.HIGHCHARTS;

  // noms des champs issues de la configuration de la base, permet d'être un peu dynamique
  // et avec de la chance, ne rien avoir à surcharger
  const id = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champId');
  const statusXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champEtat');
  const titleXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champTitre');
  const pdf = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champPdf');

  // noms des champs en durs, difficile de les rendre dynamique en l'état de l'API
  // on prend le premier élément du tableau de valeur s'il y en a plusieur.
  let textOriginal = cleanMultipleLines(getValueOrFirstValueFromArray(qesdocument?.Text_original));
  const text = cleanMultipleLines(getValueOrFirstValueFromArray(qesdocument?.Text));
  let textTranslated = '';
  if (textOriginal) {
    textTranslated = text;
  } else {
    textOriginal = text;
  }

  const recordingDateZoned = useMemo(
    () => {
      const d = new Date(getValueOrFirstValueFromArray(qesdocument?.Date_publication));
      return toZonedTime(d, 'UTC');
    },
    [qesdocument?.Date_publication],
  );

  const thumbXml = getValueOrFirstValueFromArray(qesdocument?.IMAGE || qesdocument?.THUMB);
  const linkout = getValueOrFirstValueFromArray(qesdocument?.URL || qesdocument?.QUOTE_URL);
  const source = getValueOrFirstValueFromArray(qesdocument?.SOURCE);
  const attachedFile = getValueOrFirstValueFromArray(qesdocument?.File);

  let attachedFileURL;
  if (attachedFile) {
    attachedFileURL = generateLinkToResource(docBaseId, attachedFile);
  }

  // dernières retouches avant le retour du résulat
  let thumb;
  if (thumbXml) {
    thumb = qesdocument?.IMAGE ? thumbXml : generateLinkToResource(docBaseId, thumbXml);
  }

  const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'flv', 'wmv', 'webm', 'mpeg'];
  const audioExtensions = ['m4a', 'wav', 'mp3'];
  const isVideo = !_.isEmpty(attachedFile) && videoExtensions.includes(attachedFile.split('.').pop().toLowerCase());
  const isAudio = !_.isEmpty(attachedFile) && audioExtensions.includes(attachedFile.split('.').pop().toLowerCase());

  const title = titleXml || `[${t('document.title_undefined')}]`;
  const status = +statusXml;

  const date = format(recordingDateZoned, 'PPPPp');
  const snippet = _.truncate(textOriginal, {
    length: resultsCompleteVisibleInResults ? 150 : 500,
    separator: /,? +/,
  });

  const transcriptionLocation = getValueOrFirstValueFromArray(qesdocument?.Text_json_location);

  // Process "heavy" fields only needed in the "complete" panel
  let transcription = null;
  if (shouldComputeCompleteFields) {
    // Transcription with sentences, speakers, translations
    const textJson = getValueOrFirstValueFromArray(qesdocument?.Text_json);
    const transcriptionAnnotations = getValueOrFirstValueFromArray(qesdocument?.Text_comments_json);
    transcription = useMemo(() => useComputedTranscription(
      textJson,
      transcriptionAnnotations,
      textTranslated,
      recordingDateZoned,
      speakersColors,
    ), [textJson, transcriptionAnnotations, textTranslated, recordingDateZoned, speakersColors]);
  }

  return {
    attachedFile,
    attachedFileURL,
    date,
    id,
    isAudio,
    isVideo,
    linkout,
    textTranslated,
    pdf,
    snippet,
    source,
    status,
    thumb,
    title,
    transcription,
    transcriptionLocation,
  };
};
