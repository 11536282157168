const en = {
  en: {
    translation: {
      collections: {
        collection: {
          create: 'Create a folder',
          create_dialog: 'Folder creation',
          created: 'Folder has been created',
          delete: 'Delete the folder',
          delete_dialog_one: 'Delete this folder',
          delete_dialog_other: 'Delete these {{count}} folders',
          delete_one: 'Are you sure you want to delete the following folder?',
          delete_other: 'Are you sure you want to delete the following folders?',
          deleted_one: '{{count}} folder deleted',
          deleted_other: '{{count}} folders deleted',
          edit: 'Edit the folder',
          edited: 'Folder has been edited',
          edit_dialog: 'Edit folder',
          error_creating: 'Could not create a folder',
          error_deleting_one: 'Could not delete the folder',
          error_deleting_other: 'Could not delete folders',
          error_editing: 'Could not edit a folder',
          params: 'Folder settings',
          type: 'Folder',
          description_placeholder: 'Description of the folder...',
          libelle_placeholder: 'The name of the folder...',
        },
        corpus: {
          add: 'Add a sono',
          create: 'Create a sono',
          create_dialog: 'Sono creation',
          created: 'Sono has been created',
          delete: 'Delete the sono',
          delete_dialog_one: 'Delete this sono',
          delete_dialog_other: 'Delete these {{count}} sonos',
          delete_one: 'Are you sure you want to delete the following sono?',
          delete_other: 'Are you sure you want to delete the following sono?',
          deleted_one: '{{count}} sono deleted',
          deleted_other: '{{count}} sono deleted',
          description_placeholder: 'This sono talks about...',
          edit: 'Edit the sono',
          edited: 'Sono has been edited',
          edit_dialog: 'Edit sono',
          error_activated: 'Could not activate the sono',
          error_creating: 'Could not create a sono',
          error_deleting_one: 'Could not delete the sono',
          error_deleting_other: 'Could not delete sono',
          error_editing: 'Could not edit a sono',
          libelle_placeholder: 'The name of the sono...',
          params: 'Sono settings',
          type: 'Sono',
        },
        cannot_add_corpus: 'You can\'t create a sono, you don\'t have any folders of your own',
        cannot_add_not_owner: 'You can\'t create sono, you are not the owner',
        cannot_delete_has_corpus: 'Please delete the present sono in order to delete the folder',
        error_fetching: 'Could not fetch folder',
        lastest_activated: 'Latest Activated sonos',
        my_collections: 'My folders',
        no_results: 'No folders',
        no_search_results: 'No folder found',
        search: 'Search for a sono or a folder...',
      },
    },
  },
};

export default en;
