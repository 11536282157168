const themeConfig = {
  LIGHT: {
    THEME: {
      custom: {
        logo: {
          height: '20px',
        },
      },
    },
  },
};

export default themeConfig;
